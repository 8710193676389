const faqs = [
  {
    title: "About Sprin",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "What is Sprin?",
            number: "1",
            texts: [
              "Sprin is on a mission to transform the way you order food. We partner with the best restaurants in the business – from local hotspots to national favourites – and bring you the food you love, right to your door.",
              "With hundreds of choices and a fleet of our own delivery riders, we’ll have your order with you in as little as 30 minutes.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Using Sprin",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "How does it work?",
            number: "1",
            texts: [
              "You can order by using the Sprin app, available on Android. Simply add your location to find all the great restaurants delivering in your area, choose your food and place your order.",
              "Once the restaurant receives your order, they’ll get to work preparing your food and then carefully package it. Once it's all ready to go, a Sprin rider will pick it up and bring it to you.",
            ],
          },
          {
            title: "What kind of restaurants are listed on Sprin?",
            number: "2",
            texts: [
              "We personally curate a high-quality and diverse selection of restaurants in your area. This can range from a top neighbourhood jollof rice spot to well-regarded national burger chain.",
            ],
          },
          {
            title: "What times can I order for?",
            number: "3",
            texts: [
              "We deliver every day from morning until late at night, and different restaurants will have different opening times. Visit the app to see which restaurants are available in your area.",
            ],
          },
          {
            title: "How is the food delivered to me?",
            number: "4",
            texts: [
              "Once you’ve placed your order, it’s sent directly to the restaurant for them to prepare and package. Once it’s ready, a Sprin rider will pick up your order and bring it to your delivery address.",
            ],
          },
          {
            title: "Why doesn't Sprin accept cash?",
            number: "5",
            texts: [
              "We only take card payments because it lets us provide you with the best possible experience. It creates a safer working environment for riders too. ",
              "You can tip your rider with cash after you have received your order. ",
            ],
          },
          {
            title: "Do I have to tip?",
            number: "6",
            texts: [
              "Whether you tip or not is completely up to you. You can tip after your rider has delivered your order to you.",
            ],
          },
          {
            title: "Is there a minimum spend?",
            number: "7",
            texts: [
              "There is no minimum spend required to place an order with us. You can order as little or as much as you like and enjoy your meal without any restrictions.",
            ],
          },
          {
            title: "How do I use a referral code?",
            number: "8",
            texts: [
              "Currently, our app supports only referral codes. Here's how you can redeem one:",
              "If you have a referral code, simply enter it during your first order at checkout in the ‘Enter a Referral Code’ field. Enjoy the benefits of your referral instantly!",
            ],
          },
          {
            title:
              "What do I do if I see an error when adding a referral or offer code?",
            number: "9",
            texts: [
              "Please contact Sprin Customer Service via Contact Us for assistance if you see an error message when trying to apply an offer or referral code.",
            ],
          },
          {
            title:
              "Do you charge the same prices as the restaurant does in the restaurant?",
            number: "10",
            texts: [
              "We encourage restaurants to always use the same pricing for delivery as they do for their in-house menu, although there may be exceptions. Pricing for each menu item is clearly displayed in the app. If you have any questions about menu pricing, please contact our customer support team through any of the customer service options available in the Contact Us section on the profile page of the Sprin app, or by visiting https://sprinapp.com/contact/",
            ],
          },

          {
            title: "How is the food packaged?",
            number: "11",
            texts: [
              'Packaging always depends on the type of food and restaurant you’re ordering from. Restaurants take great care to use packaging that maintains the correct temperature for as long as possible. If you have suggestions regarding the packaging or appearance of your food when it’s delivered, please visit https://sprinapp.com/contact/. Click on "Contact Us" to complete the form, and we’ll pass on your feedback to the restaurant.',
            ],
          },
          {
            title:
              "Some menus include customer feedback about the partner - what is this?",
            number: "12",
            texts: [
              "When you place an order on Sprin, you may be asked for feedback about your experience. We may then share this feedback with our users, including on menu pages. Sprin does not verify customer feedback and accepts no responsibility for it or its accuracy. As set out in our terms of service, customers are responsible for ensuring that any product that they order from a partner on Sprin is suitable for them.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Questions about my order",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "What if something is wrong with my order?",
            number: "1",
            texts: [
              "We have a dedicated team that looks after your entire Sprin experience, from the moment you place an order right through to it arriving with you.",
              "However, we do understand that sometimes things might go wrong. If this is the case, please contact our customer support team through any of the customer service options available in the Contact Us section on the profile page of the Sprin app, or by visiting https://sprinapp.com/contact/ and report any issues.",
            ],
          },
          {
            title: "What if my order is late?",
            number: "2",
            texts: [
              "Sometimes things outside of a rider’s control can cause a delay. Where we can, we will always try and proactively call you if we become aware that your order might not arrive within the estimated time of delivery, and our team will work to get your order to you as quickly as possible.",
            ],
          },
          {
            title: "What if I’m not around when my rider arrives?",
            number: "3",
            texts: [
              "If you think you won’t be at the delivery address in time to receive your order, please let us know by using the customer service options available in the Contact Us section on the profile page of the Sprin app.",
              "If your rider can't find you, they will follow a series of steps, such as trying to call you or send you a push notification, once they reach your delivery address. If they’re unable to contact you, they will try to leave your order in a safe place. Please check your emails during the delivery time for any updates.",
              "You’ll still be charged for your order if we can see that your rider has made attempts to reach you. To prevent this from happening, it’s always a good idea to double-check your contact and address details.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Fees on Sprin",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "Delivery fee",
            number: "1",
            texts: [
              "The delivery fee is variable, based on your location. You pay less for nearby restaurants and you'll always know how much before placing your order.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Inviting friends",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "I can't see a referral code – where is it?",
            number: "1",
            texts: [
              'If you can’t find your referral code, don’t worry! You can easily access your referral code in the "Refer to a Friend & Earn" section on your profile page. Just head over to your profile, tap on the option, and you’ll find your unique referral code ready to share with friends and family.',
            ],
          },
          {
            title: "How do I use my referral credit?",
            number: "2",
            texts: [
              "When someone you referred uses your referral code on their first order, the credit will automatically be added to your wallet balance. You can then use this credit on your next order.",
              "To apply the credit to an order, simply toggle on the “Use Wallet Balance” option on the checkout page. If the toggle is selected, all available credit in your wallet will be applied to the order total. It’s that simple!",
            ],
          },
          {
            title: "I'm not a new user. Can I get free credit if I sign up?",
            number: "3",
            texts: [
              "Sorry, this offer is only valid for new users of Sprin. And don't keep making accounts, we'll know! To find out more, please go to our T&C's.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Anything else?",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "What if I have allergies?",
            number: "1",
            texts: [
              "If you have specific allergies and are concerned about any items on a menu, please check the food description section and for further information contact our customer support team prior to ordering. Increasing numbers of Partner Restaurants will be displaying dish by dish allergens information. Where that information is not available or if you have further questions, please contact our customer support team through any of the customer service options available in the Contact Us section on the profile page of the Sprin app, or by visiting https://sprinapp.com/contact/ prior to ordering if you have an allergy.",
            ],
          },
          {
            title: "When will you be delivering in my area?",
            number: "2",
            texts: [
              "We are rapidly expanding and will hopefully be working with restaurants near you soon!",
            ],
          },
          {
            title: "Does Sprin offer company accounts?",
            number: "3",
            texts: [
              "We have a dedicated team who will be more than happy to assist you with setting up an account so you can get team morale-boosting breakfasts, next-level meeting lunches and even catering. Get in touch with us at sprinapp@gmail.com",
            ],
          },
          {
            title: "Is there a Sprin app?",
            number: "4",
            texts: ["There is! It’s available free on Google Play."],
          },
          {
            title:
              "What is this Customer Champion advert on the app home screen?",
            number: "5",
            texts: [
              "This banner showcases local restaurants that have fulfilled several conditions of service quality and customer satisfaction. These include hygiene rating, timely and reliable preparation, high customer ratings, and having completed enough orders on Sprin so we can accurately judge their quality. This criteria will be reviewed over time to ensure they reflect our customers’ experiences. ",
            ],
          },
          {
            title:
              "How are the restaurants selected to be on the list I see when I click on the advert?",
            number: "6",
            texts: [
              "Local restaurants automatically qualify when they fulfill several conditions of service quality and customer satisfaction, including hygiene, timely and complete order delivery, and customer rating criteria.  ",
            ],
          },
          {
            title: "Can restaurants pay Sprin to be advertised on this list?",
            number: "7",
            texts: [
              "No, restaurants cannot be featured on this list by paying Sprin. The only criteria used are those relating to compliance with hygiene, timely and complete order delivery, and customer satisfaction. ",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Payments",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "What do I do if I have a charge I do not recognise?",
            number: "1",
            texts: [
              "Sprin finds that unrecognised charges can often be attributed to a friend, coworker, or family member using your payment method. We ask that you check with your family and friends to see if they may have used your card to place an order via Sprin.",
              "Otherwise please visit https://sprinapp.com/contact/ and send us a message.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Food Safety",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "I was sick after consuming food. What do I do?",
            number: "1",
            texts: [
              "Food safety is our top priority, and we’d like to address this for you. Please reach out to us through any of the customer service options available in the Contact Us section on the profile page of the Sprin app, or by visiting https://sprinapp.com/contact/ ",
            ],
          },
          {
            title: "I found a foreign object in my food. What do I do?",
            number: "2",
            texts: [
              "Please reach out to us through any of the customer service options available in the Contact Us section on the profile page of the Sprin app, or by visiting https://sprinapp.com/contact/ and provide us with answers to the following questions so our team can help:",
              "What was the unexpected object you found?, Where did you find the object?, Did you eat/drink the affected item(s)?, Clear photographs of the object and affected item(s) ",
              "If you could also please let us know if you give consent to pass your contact details (name, phone number, and email) on to the restaurant for further investigation. ",
            ],
          },
          {
            title: "I had an allergic reaction after consuming my food",
            number: "3",
            texts: [
              "If you experienced an allergic reaction after consuming your order please contact us through any of the customer service options available in the Contact Us section on the profile page of the Sprin app, or by visiting https://sprinapp.com/contact/ and provide us with answers to the following questions so our team can help:",
              "What dish(es) did you eat/drink?, What symptoms did you have?, When did your symptoms start appearing, and how long did they last for?, Did you seek medical attention? If you visited a doctor, please let us know of any medicine they prescribed for you, Do you have any allergies? If so, please list them, If you have allergies, did you let the restaurant know about them?, ",
              "If you could also please let us know if you give consent to pass your contact details (name, phone number, and email) on to the restaurant for further investigation. ",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Complaints about a Rider",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "If you had an issue with one of our riders you would like to let us know about, you can contact us through any of the customer service options available in the Contact Us section on the profile page of the Sprin app, or by visiting https://sprinapp.com/contact/ and provide us with answers to the following questions so our team can help:",
            number: "1",
            texts: [
              "a short description of the incident;",
              "location of the incident (town/city and street);",
              "time and date of the incident (the more specific, the better);",
              "whether this was a Cyclist/Scooter/Motorbike/Car; and ",
              "anything else that can help us to identify the rider.",
              "We will investigate the matter and a member of our team will reach out to you.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Community Guidelines",
    description:
      "At Sprin we want our customers, riders and restaurant partners to have the best possible food delivery experience. At the same time, we want to ensure that the communities we work with are safe. These guidelines are designed to help ensure that everyone who uses the Sprin platform has a safe and positive experience. ",
    slides: [
      {
        sections: [
          {
            title: "Customers",
            number: "1",
            texts: [
              "Be respectful: Our riders are at the heart of our business, collecting food from our restaurant partners and delivering it to your door. We ask that you treat riders with respect and report any behaviour that you find unprofessional to us directly by contacting Customer Support, so we can take appropriate action. We want everyone who uses Sprin to have a great experience, and being respectful to one another is key. ",
              "Be prompt: When you receive a notification that your rider is on their way to you, please ensure that you are ready to collect your food. This is so that our riders do not have to spend time waiting around for customers to come and collect their food when they could be working. Additionally, we ask that you use ‘Delivery notes’ when adding your delivery location where you would like your food to be delivered to, so that your rider does not have to search for your location.",
              "Fraud: Fraudulent activity undermines the trust that we have built with our community. Committing fraud is a criminal offence. We have a number of measures in place to monitor fraudulent activity from customers, as well as riders and restaurants. If an account is found or suspected to be engaging in fraudulent activity, we will take action which may result in: Deactivation of your Sprin account; and/or Escalation to the relevant authorities. ",
            ],
          },
        ],
      },
    ],
  },
];

const privacy = [
  {
    title: "Who we are & our policy",
    description:
      "By accessing and using our website or app, you agree to comply with these policies. Please review them carefully before proceeding.",
    slides: [
      {
        sections: [
          {
            title:
              'Sprin Technologies Limited ("Sprin," "we," "us," "our") offers a platform for users to order food from nearby restaurants and have it delivered to their doorsteps (“Services”). Transparency is a fundamental value at Sprin, and we are committed to clearly explaining how we handle your personal information. Personal information includes unique data or aggregated information that can identify you as an individual when processed with other details. We encourage you to read this privacy notice ("Notice") carefully to understand your rights and our obligations regarding your personal data, alongside our Terms of Use. If you have any questions about either document, please email us at sprinapp@gmail.com.',
            number: "1",
            texts: [],
          },
          {
            title:
              "Document version control: This Notice is subject to change and may be updated due to legislative changes, business needs, or alterations in our operational practices. Any modifications to this Notice will be approved by the authorized officers.",
            number: "2",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Personal data",
    description: "Everything you should understand about personal information",
    slides: [
      {
        sections: [
          {
            title: "Purpose of Collecting Your Personal Information",
            number: "1",
            texts: [
              "Deliver Sprin Services and manage the restaurant listings in your area.",
              "Notify you about various product options.",
              "Fulfill our legal and regulatory responsibilities.",
              "Innovate, refine, and expand our services.",
              "Confirm your identity, ensure the accuracy of the data, and prevent fraudulent activities.",
              "Offer customer support to you.",
              "Detect and prevent fraud for your safety.",
              "Use it for any other purpose with your consent.",
            ],
          },
          {
            title: "Consent",
            number: "2",
            texts: [
              "You accept this privacy notice by clicking the “accept” button to opt-in and use our platforms, services, features, technologies, or functions offered on our website and digital platforms, or by visiting any of our offices for official or non-official purposes. If you decide to opt out, you can do so by contacting us at sprinapp@gmail.com. This privacy notice governs the use of Sprin Services, and if you are not comfortable with us processing your data, you may choose not to use our Services.",
            ],
          },
          {
            title: "How We Collect Your Personal Information",
            number: "3",
            texts: [
              "We gather information directly from you and automatically through your use of our Services, and occasionally from third parties with your authorization.",
              "When you fill out a form, participate in a contest or promotion, request customer service, or otherwise communicate with us, we collect your information to better understand your needs and provide relevant services to you.",
              "Additionally, we may receive information about you from other sources and combine it with the data we collect directly. Examples of such information from other sources include updated delivery or payment details to maintain accurate records, purchase or redemption information, as well as customer support and enrollment information.",
            ],
          },
          {
            title:
              "What Personal Information Do We Collect About You: We only collect essential information for you to create an account with Sprin and utilize our Services.",
            number: "4",
            texts: [
              "Your personal details related to your account, such as your name, age, gender, address, location, email address(es), and mobile contact number. Additionally, we may collect the name, location, phone number, and other information about third parties related to you or their use of our services if provided to us.",
              "Details concerning when and where you request our Services and the specifics of any orders placed or received by you concerning our Services, including your geographical location.",
              "Information about the device on which any of our Platforms or applications have been installed or accessed, which may include your operating system and versions, unique device identifiers, serial numbers, mobile network, and Wi-Fi information.",
              "Personal information obtained and retained through financial or third-party payment systems about the payment mechanism or method you might use (including credit card details) and the payments you make for our services or other goods or services you request or receive related to the services we provide.",
              "Your connections to other individuals whose personal information we may collect or retain, such as family members, friends, or referrals.",
              "Details about your participation in any loyalty programs linked or available through us or any of our business partners or affiliates.",
              "If you use social media to interact with us or other services provided by third parties through Sprin, any information that you permit the social media platform and/or the other third-party site to share with us.",
              "Any address you may utilize for delivery services.",
              "If you respond to optional survey requests, demographic information including age, marital status, ethnicity, profession, and income.",
            ],
          },
          {
            title:
              "How We Use Your Personal Information: The purpose of collecting your personal information is to provide you with efficient, enjoyable, and secure Service.",

            number: "5",
            texts: [
              "Provide our Services to you.",
              "Communicate with you.",
              "Notify you of any important information or content that you may be interested in.",
              "Conduct research and development.",
              "Ensure and enhance the safety and security of Users and relevant third parties.",
              "Provide customer support services such as facilitating responses to your inquiries or complaints.",
              "Conduct customer surveys and research activities.",
              "Conduct marketing, promotions, and advertising activities.",
              "Fulfill our obligations or claim our rights in legal proceedings.",
              "Facilitate communications between Users.",
              "Authenticate Users.",
              "Investigate and prevent possible fraud and illegal activity.",
              "Process and facilitate payment for services facilitated by us.",
              "Deal with disputes and complaints.",
              "Comply with laws, regulations, and rules, including assisting government agencies and law enforcement investigations.",
              "Report to regulatory authorities or government bodies on regulatory compliance.",
              "Use or disclose it as otherwise authorized or permitted by law.",
              "Contact you at any time through your provided telephone number, email address, or other contact details.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Information Regarding Your Personal Data",
    description:
      "This section outlines the procedures, rights, and considerations associated with the management and handling of individuals' personal data by the service provider.",
    slides: [
      {
        sections: [
          {
            title:
              "Your Rights: You have the right to make the following requests regarding your personal information within our custody.",
            number: "1",
            texts: [
              "Confirmation of the existence and use of personal information about you.",
              "Access to the personal information we hold about you, provided such requests comply with relevant enactments.",
              "Correction or updates to the personal information we hold about you.",
              "Anonymization, blocking, or erasure of your personal information that is no longer necessary for our services or normal business operations.",
              "Data portability, allowing you to request a structured, machine-readable copy of your personal information to a third-party service provider.",
              "Deletion or erasure of personal information we hold about you, where applicable legal or regulatory obligations permit.",
              "Information regarding third parties to whom your personal information is disclosed, not covered in other sections.",
              "Revocation of your consent, where applicable.",
            ],
          },
          {
            title:
              "Making Requests: You can make any of the above requests by contacting us. We may require you to complete a form and provide suitable identification to verify your identity.",
            number: "2",
            texts: [],
          },
          {
            title:
              "Response Time: We aim to respond promptly. Simple requests will be addressed within 2-3 days, while complex ones may take up to 20 working days. If unable to comply for any reason, we will inform you.",
            number: "3",
            texts: [],
          },
          {
            title:
              "Confidentiality: If the information provided in response to your request includes third-party personal information or confidential data, we may redact such information as necessary. You must keep this information confidential and refrain from sharing it without our written consent.",
            number: "4",
            texts: [],
          },
          {
            title:
              "Account Deletion: Users can delete their Sprin account. Upon deletion, we will retain personal information in accordance with our record-keeping policies and obligations.",
            number: "5",
            texts: [],
          },
          {
            title:
              "Retention Policy: We may retain your personal information if:",
            number: "6",
            texts: [
              "There are unresolved issues relating to your account.",
              "We are required by applicable law.",
              "It is necessary for legitimate business interests, such as fraud prevention.",
            ],
          },
          {
            title:
              "Data Disposal: When personal information is no longer required, we will take reasonable steps to delete it from our systems or de-identify it.",
            number: "3",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Disclosures of Your Personal Information",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin will not sell or rent your information to third parties. However, we may disclose your information:",
            number: "1",
            texts: [
              "To related bodies corporate, business partners, and service providers or vendors we engage, such as customer service providers, to help us provide our Services or conduct data processing on our behalf. These entities may be located and operate overseas, except where an exception under the Nigerian Data Protection Regulation Legislation applies.",
              "Subject to any limitations in your information permissions and settings, we may share your information for our everyday business purposes, such as processing your orders and delivery purposes.",
              "With your consent and direction or as otherwise required by law.",
            ],
          },
          {
            title:
              "Sprin will comply with its obligations under the relevant privacy law in relation to any such disclosures, including using reasonable commercial efforts to ensure that such third parties only use your personal information as reasonably required for the purpose of disclosure and in a manner consistent with the privacy legislation. For example, (where commercially practicable) by including suitable privacy and confidentiality clauses in our agreements with those third parties.",
            number: "2",
            texts: [],
          },
          {
            title:
              "Your privacy and information permissions and settings can be updated at any time on the Sprin mobile application when you are logged into your account.",
            number: "3",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Third-Party Technologies",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "At times, we may choose to integrate or offer third-party products or services on our Platform. These third-party sites operate under separate and independent privacy policies. Consequently, we hold no responsibility or liability for the content and activities of these linked sites. However, we are committed to safeguarding the integrity of our Platform and welcome any feedback regarding these sites.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Location Services",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Upon your initial use of our Platform, we will request your permission to access your location. Location services are essential for us to deliver our Services effectively. You have the flexibility to adjust these settings on your device at any time. With your consent, we gather your location through various means such as IP address, GPS, and other sensors necessary for Service provision. Additionally, we may collect Wi-Fi metadata from your device to recommend nearby restaurants, including Wi-Fi and GPS status, MAC address, SSID, signal levels, and GPS location relative to Wi-Fi networks.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Communication",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "The Sprin Platform allows anonymous communication via call or message once food has been purchased for delivery. We record all communications (voice and text) for safety, monitoring, quality assurance, fraud prevention, and analytical purposes to enhance our services and investigate disputes or complaints. Your consent to recording and using these communications is implied by using this functionality, as outlined in this Privacy Notice.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Your Social Media Use",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin utilizes third-party platforms like Instagram and Twitter for interactive information sharing and user connectivity. We advise you to review the terms and conditions, including privacy policies, of any third-party web service concerning the handling of your personal information. It's important to maintain the confidentiality of your personal information when interacting with them through direct or private messages, email, or customer service hotlines.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Surveys",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "We may invite you to participate in surveys to gain insights into our Services and enhance them. Your participation may involve providing demographic information such as age, marital status, ethnicity, profession, and income, which could include sensitive data. Additionally, we may collect information about your usage patterns and experiences with our Services. By participating in surveys, you consent to our collection and utilization of the provided information in accordance with this privacy notice.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },

  {
    title: "Disclaimer",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin has no prior relationship or representation with the restaurants whose directory services are listed on Sprin’s Platform. Sprin is solely responsible for the purchase of the customer's order and the delivery of the order to the customer's provided address upon confirmation of payment.",
            number: "1",
            texts: [],
          },
          {
            title:
              "Sprin will not be responsible for any breach in the representations made by the restaurant concerning any order made by you and does not guarantee that the representations will be true and accurate.",
            number: "2",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Storage and Security",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "We protect your information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, and unauthorized access. Personal information we collect is stored on servers in electronic databases managed by our related entities and/or service providers.",
            number: "1",
            texts: [],
          },
          {
            title:
              "We have implemented suitable physical, electronic, and administrative procedures and measures to safeguard and protect the information we collect about you, and to reduce the risks of loss, misuse, disclosure, alteration, and unauthorized access to your personal information within our custody. Personal information and associated data stored on servers is encrypted and stored through data isolation technology. We limit access to personal information to individuals within our employ, our related entities, or contracted service providers who we believe reasonably need access to such information to provide products or services to you, to us, or to perform their duties. Some of the safeguards we use include firewalls and data encryption, physical access controls to our data centers, and information access authorization controls.",
            number: "2",
            texts: [],
          },
          {
            title:
              'To prevent unauthorized access to your information, we have implemented strong controls and security safeguards at the technical and operational levels. Our website uses Secure Sockets Layer (SSL) to ensure secure transmission of your personal data. You should see the padlock symbol in your URL address bar when browsing through our website. The URL address will also start with "https://," depicting a secure webpage. SSL applies encryption between two points, such as your PC and the connecting server. Any data transmitted during the session will be encrypted before transmission and decrypted at the receiving end, ensuring that data cannot be read during transmission.',
            number: "2",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Retention Period",
    description:
      "Subject to the provisions of the NDPR 2019, the retention period of personal information of users of Sprin shall be as follows:",
    slides: [
      {
        sections: [
          {
            title:
              "Five (5) years after the last active use of our digital platform.",
            number: "1",
            texts: [],
          },
          {
            title:
              "Upon presentation of evidence of death by a deceased’s relative, the personal information of such user will be discarded.",
            number: "2",
            texts: [],
          },
          {
            title:
              "Immediately upon request by the user of Sprin or their legal guardian, provided that:",
            number: "3",
            texts: [
              "No statutory provision states otherwise and the user is not the subject of an investigation or suit that may require retention of the personal information sought to be deleted.",
            ],
          },
        ],
      },
    ],
  },
];

const terms = [
  {
    title: "General Terms",
    description:
      'Welcome to sprinapp.com, a proprietary website/application of Sprin Technologies Limited ("Sprin," "we," "us"). By accessing this Platform, you ("User," "you," "your") agree to the following terms and conditions. While we understand that legal documents can be tedious and often skipped, it is crucial to read these terms carefully before using the Platform. Your use of this Platform constitutes agreement to these terms. If you have any questions or require clarification, please contact us at sprinapp@gmail.com. We are happy to assist you.',
    slides: [
      {
        sections: [
          {
            title:
              'These terms govern your use of our website and mobile application (collectively referred to as the "Platform") and outline the legally binding conditions for accessing, browsing, registering, or using the Platform\'s services. These terms apply to all "Users," including guest Users.',
            number: "1",
            texts: [],
          },
          {
            title:
              "By using the Platform, you confirm your acceptance of these terms and agree to comply with and be bound by them.",
            number: "2",
            texts: [],
          },
          {
            title:
              "Any violation of these terms may result in restrictions, suspension, or withdrawal of your rights to use the Platform's services.",
            number: "3",
            texts: [],
          },
          {
            title:
              "We may update these terms at any time by amending this page. Please check periodically for changes, as they are binding. Existing Users will be notified of amendments via prompts on the mobile application.",
            number: "4",
            texts: [],
          },
          {
            title:
              "If you do not agree with these terms, please refrain from accessing the Platform or using its services.",
            number: "5",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Services",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "This Platform enables Users to request one of our Deckers to place orders.",
            number: "1",
            texts: [],
          },
          {
            title:
              "The Platform offers a variety of restaurants where you can choose meals to order and have them delivered.",
            number: "2",
            texts: [],
          },
          {
            title:
              "We do not control or guarantee the existence, quality, or safety of advertised food. Additionally, we do not guarantee the accuracy of restaurant offerings or their ability to deliver as described.",
            number: "3",
            texts: [],
          },
          {
            title:
              "While we provide delivery services, we disclaim any warranties (explicit or implied) regarding the quality of meals ordered via the Platform to the fullest extent allowed by law.",
            number: "4",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Accessing our Platform and the Services",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "We do not guarantee that the Platform or its services will always be available, uninterrupted, or error-free. We are not liable if the Platform is unavailable at any time.",
            number: "1",
            texts: [],
          },
          {
            title:
              "We do not guarantee that the Platform or its content will be free from errors or omissions.",
            number: "2",
            texts: [],
          },
          {
            title:
              "You are responsible for securing access to the Platform and ensuring compliance with these terms by anyone using your account.",
            number: "3",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Membership and Registration",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Services are available only to individuals aged 18 or older who can form legally binding contracts. You represent that you meet these requirements and that your registration information is accurate and truthful.",
            number: "1",
            texts: [],
          },
          {
            title: "Eligible Users must create an account to access services.",
            number: "2",
            texts: [],
          },
          {
            title:
              "Account creation requires providing your full name, email address, phone number, and delivery address. A one-time password will be provided upon signup.",
            number: "3",
            texts: [],
          },
          {
            title:
              "You are solely responsible for safeguarding your login details. Notify us immediately at sprinapp@gmail.com in case of unauthorized access.",
            number: "4",
            texts: [],
          },
          {
            title:
              "Once registered, you can select restaurants, place orders, and request delivery to your registered address.",
            number: "5",
            texts: [],
          },
          {
            title:
              "We reserve the right to require a password change or suspend accounts in case of suspected security breaches.",
            number: "6",
            texts: [],
          },
          {
            title: "As a user, you:",
            number: "7",
            texts: [
              "Ensure all account information is accurate and up-to-date.",
              "Commit to updating account details as needed.",
              "Accept responsibility for any consequences arising from incorrect or outdated information.",
            ],
          },
          {
            title:
              "We may verify account information at any time and request additional documents for identity verification.",
            number: "8",
            texts: [],
          },
          {
            title:
              "You may terminate your account at any time, resulting in the deletion of your details from the Platform.",
            number: "9",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Payment",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "Payments are processed by a third-party payment gateway.",
            number: "1",
            texts: [],
          },
          {
            title: "Orders can be paid using:",
            number: "2",
            texts: [
              "The Platform’s electronic wallet feature.",
              "Debit cards.",
              "Bank transfers.",
              "QR codes.",
              "Other payment methods as made available by our partners.",
            ],
          },
          {
            title:
              "The payment processor handles all transactions on the Platform.",
            number: "3",
            texts: [],
          },
          {
            title:
              "Meal prices are displayed in Naira and include applicable taxes.",
            number: "4",
            texts: [],
          },
          {
            title: "Delivery fees are shown at checkout.",
            number: "5",
            texts: [],
          },
          {
            title:
              "Total prices are displayed at checkout and due at order placement.",
            number: "6",
            texts: [],
          },
          {
            title: "By creating an account, you agree to these payment terms.",
            number: "7",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Intellectual Property",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "We and our partners own all intellectual property rights to the Platform and its content, protected under Nigerian copyright laws. You may use these materials for personal purposes only.",
            number: "1",
            texts: [],
          },
          {
            title:
              "Unauthorized reproduction, modification, or distribution of the Platform’s content is prohibited and may result in legal action.",
            number: "2",
            texts: [],
          },
          {
            title:
              "If you believe your copyright has been infringed, contact us at sprinapp@gmail.com. We will address the issue promptly.",
            number: "3",
            texts: [],
          },
          {
            title:
              "Descriptions of food menus are user-generated. If inaccuracies are identified, contact us for corrections.",
            number: "4",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "No Reliance",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "The information about meals provided on our Platform is intended for general purposes only and should not be relied upon as advice. While we strive to keep the information on our site accurate and up-to-date, we make no explicit or implied representations or guarantees regarding its completeness or currency. You acknowledge that relying on such information is solely at your own risk.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },

  {
    title: "Limitation of Liability",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "These terms of use do not exclude or limit our liability for personal injury caused by our negligence, fraudulent misrepresentation, or any other liability that cannot be excluded or limited under Nigerian law.",
            number: "1",
            texts: [],
          },
          {
            title:
              "To the fullest extent permitted by law, we disclaim all conditions, warranties, representations, or other terms that might otherwise apply to our Platform or its content, whether express or implied.",
            number: "2",
            texts: [],
          },
          {
            title:
              "We are not responsible for the content of third-party websites linked to our Platform. These links do not imply endorsement, and we will not be liable for any loss or damage arising from their use.",
            number: "3",
            texts: [],
          },
          {
            title:
              "Our Platform is intended solely for domestic and private use. You agree not to use it for commercial or business purposes without our explicit written consent. We are not liable for any loss of profit or business opportunity arising from unauthorized use of the Platform.",
            number: "4",
            texts: [],
          },
          {
            title:
              "To the extent allowed by law, Sprin is not liable for any indirect, punitive, incidental, special, or exemplary damages resulting from your inability to use our Services.",
            number: "5",
            texts: [],
          },
          {
            title:
              "We are not responsible for damages resulting from unauthorized use, tampering, or access to your Sprin account.",
            number: "6",
            texts: [],
          },
          {
            title:
              "Sprin is not liable, and you agree not to hold Sprin responsible for any damages or losses arising directly or indirectly from:",
            number: "7",
            texts: [
              "delays or disruptions in our Services, software, or Platform;",
              "your inability to access or use our Services or Platform;",
              "suspensions or other actions taken concerning your Sprin account;",
              "use or access of our Services inconsistent with these terms of use, or errors, inaccuracies, losses, or omissions in any data provided to us; and",
              "damage caused by viruses or other harmful materials affecting your electronic devices due to the use of our Platform or linked websites.",
            ],
          },
          {
            title:
              "We are not liable for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising from the use of or inability to use our Platform, even if foreseeable.",
            number: "8",
            texts: [],
          },
        ],
      },
    ],
  },

  {
    title: "Indemnification",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "You agree to indemnify, defend, and hold Sprin harmless from any loss, damage, liability, or claim arising from or related to your:",
            number: "1",
            texts: [
              "Use or misuse of our Services;",
              "Violation of these terms of use or any applicable laws.",
            ],
          },
          {
            title:
              "We reserve the right, at your expense, to take exclusive control and defense of any matter for which you are obligated to indemnify us. You also agree to cooperate with us in defending such claims.",
            number: "2",
            texts: [],
          },
        ],
      },
    ],
  },

  {
    title: "Term and Termination",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "These terms of use take effect from the date you begin using our services and remain in force until terminated by either you (by closing your account) or us.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },

  {
    title: "Restricted Activities",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Our Platform is intended for lawful use only. You must not, nor allow others to, use it for any purpose beyond its intended scope.",
            number: "1",
            texts: [],
          },
          {
            title: "As a user of our Platform and Services, you agree not to:",
            number: "2",
            texts: [
              "Use them in a manner that violates any applicable law or regulation;",
              "Engage in unauthorized or harmful activities affecting us;",
              "Perform fraudulent acts, including providing false identification;",
              "Modify, adapt, decipher, disassemble, or reverse engineer any software that is part of our Platform.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Electronic Communication",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "By using our Services, you consent to receiving electronic communications, which include notices, disclosures, and other messages delivered via email or displayed on our Platform. These satisfy any legal requirement for written communication.",
            number: "1",
            texts: [],
          },
          {
            title:
              "You agree to use electronic signatures, contracts, orders, and records for transactions initiated or completed by you. You waive any rights or legal requirements for original signatures or non-electronic records where applicable.",
            number: "2",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Severability",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "If any provision of these terms is deemed invalid, unlawful, or unenforceable by a competent authority, that provision will be severed to the necessary extent. The remaining terms will continue to apply to the fullest extent permitted by Nigerian law.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Personal Data",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "We collect and process Users’ personal data. For more information, please review our Privacy Policy. Except for our payment providers, we do not share your details with third parties.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Sanctions for Breaches",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "In cases of fraud, violations of these terms, or breaches of applicable laws, we may take appropriate action, including:",
            number: "1",
            texts: [
              "Suspending or terminating access to our services for the offending User;",
              "Notifying relevant authorities and pursuing legal action.",
            ],
          },
          {
            title:
              "Suspensions or terminations will take effect immediately upon our communication to the User, and their account will be permanently deleted without further notice.",
            number: "2",
            texts: [],
          },
        ],
      },
    ],
  },

  {
    title: "Placing Orders",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "The product images on our Platform may include stock photos used for illustrative purposes. While we strive for accuracy, variations may occur.",
            number: "1",
            texts: [],
          },
          {
            title:
              "All order-related communications and pages on the Platform will be in English. You will have the ability to make, review, and correct your order before confirming it.",
            number: "2",
            texts: [],
          },
          {
            title:
              "The checkout page is where orders are confirmed. By confirming, you acknowledge making all necessary corrections to your order.",
            number: "3",
            texts: [],
          },
          {
            title:
              "To place an order, select a restaurant and the desired items from the homepage list on the Platform.",
            number: "4",
            texts: [],
          },
          {
            title:
              "If your preferred restaurant is not listed, you may request its addition. However, we are not obligated to fulfill such requests.",
            number: "5",
            texts: [],
          },
          {
            title:
              "You can review your order summary at any time before confirming it on the checkout page.",
            number: "6",
            texts: [],
          },
          {
            title:
              "Order confirmation serves as an offer to purchase selected meals. Cancellations are allowed only before the restaurant accepts the order. After acceptance, cancellations are not permitted.",
            number: "7",
            texts: [],
          },
          {
            title:
              "The Platform provides a tool summarizing your orders and payment history, accessible at any time.",
            number: "8",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Delivery of Your Order",
    description: "",
    slides: [
      {
        sections: [
          {
            title: "Meal delivery is a direct agreement between you and Sprin.",
            number: "1",
            texts: [],
          },
          {
            title:
              "You agree to accept delivery of meals ordered via the Platform.",
            number: "2",
            texts: [],
          },
          {
            title:
              "If an order is incomplete, contact Sprin’s customer service immediately. Missing items will be delivered, or you may receive a refund for undelivered items.",
            number: "3",
            texts: [],
          },
          {
            title:
              "You cannot request a refund or delayed delivery for orders you failed to collect.",
            number: "4",
            texts: [],
          },
          {
            title:
              "Ensure the accuracy of your delivery details, including address and phone number.",
            number: "5",
            texts: [],
          },
          {
            title:
              "For incorrect or mixed-up orders, contact Sprin immediately for a return. The items must remain untampered and in their original state.",
            number: "6",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Complaints",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Submit complaints to our customer support team via the Contact Us section in the Sprin app profile page or at https://sprinapp.com/contact/",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Disclaimer",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Services are provided 'As Is' and 'As Available.' Sprin disclaims all warranties not explicitly stated in these terms. We make no guarantees about restaurant relationships, service reliability, or uninterrupted usage. Your use of the Services is at your own risk to the fullest extent allowed by law.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Promotional Codes",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin may issue promotional codes ('Promo Codes') for discounts or other benefits subject to specific terms. You agree that Promo Codes:",
            number: "1",
            texts: [
              "must be used lawfully and for their intended purpose;",
              "cannot be duplicated, sold, transferred, or made public;",
              "may be deactivated by Sprin without liability;",
              "are subject to the terms specified for each code;",
              "have no cash value; and",
              "may expire before usage.",
              "Sprin reserves the right to withhold or revoke benefits if Promo Codes are used fraudulently or in violation of terms.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Governing Law",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "These terms are governed by Nigerian law. Disputes will be resolved exclusively in Nigerian courts.",
            number: "1",
            texts: [],
          },
        ],
      },
    ],
  },
];

export { faqs, privacy, terms };
